import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    youtubeResponses: [],
    oauthToken: null,
  },
  mutations: {
    ADD_RESPONSE(state, payload) {
      state.youtubeResponses.push(payload);
    },
    CLEAR_LIST(state) {
      state.youtubeResponses = [];
    },
    SET_TOKEN(state, payload) {
      state.oauthToken = payload;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState(
      process.env.NODE_ENV === "production"
        ? {
            storage: {
              getItem: (key) => ls.get(key),
              setItem: (key, value) => ls.set(key, value),
              removeItem: (key) => ls.remove(key),
            },
          }
        : ""
    ),
  ],
});
