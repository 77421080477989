<template>
  <v-app>
    <v-app-bar app>
      <div class="d-flex align-center">
        <a
          @click="goHome"
          class="d-flex align-center grey--text text--darken-2"
        >
          <v-icon left>mdi-hammer-screwdriver</v-icon>
          <h1 class="text-h6">Yiosh's Youtube Query Builder Tool</h1>
        </a>
      </div>

      <v-spacer></v-spacer>

      <v-btn :to="{ name: 'Videos' }" icon>
        <v-icon>mdi-video</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// import axios from "axios";
export default {
  name: "App",

  data: () => ({
    query:
      "ya29.a0ARrdaM_qtHn-jHRs8GyHG3LU3df0XYKVaOReS3EPQdjWaJ2CrKHMFuW6Jg5hYC50VFY9Ie1wpgTAwrM1MdaYGiVG8ZxjKFe29PIr4lBa7VnLA4vU6M2YUaWpfd2o-u7-C0f0eUKL3qbObdWs1tuzyZ4pq1TW&token_type=Bearer&expires_in=3599&scope=https://www.googleapis.com/auth/youtube.force-ssl",
    //
  }),
  methods: {
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>
