<template>
  <div class="home">
    <h1>Response</h1>
    <code v-if="$store.state.oauthToken">
      {{ $store.state.oauthToken }}
    </code>
    <code>
      {{ $store.state.youtubeResponses }}
    </code>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
};
</script>
